import React, { useEffect } from "react";

const AppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Redirect based on user agent
    if (/android/i.test(userAgent)) {
      // Use market:// scheme to open the Play Store directly
      window.location.href = "market://details?id=com.feastify";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS redirection to App Store
      window.location.href =
        "https://apps.apple.com/gb/app/feastify-food-rescue-app/id6502233064";
    } else {
      // Optionally, you can show a message or link for desktop users
      alert("Please use a mobile device to download the app.");
    }
  }, []);

  return <></>;
};

export default AppRedirect;
