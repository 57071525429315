import React, { useState } from "react";
import "./DeletePage.css";

const DeletePage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(false);
  };

  const handleLogin = () => {
    // Add your login logic here
    console.log("Username:", username);
    console.log("Password:", password);
    // Add your validation logic here
    setError(true);
  };
  return (
    <div className="main">
      <div className="left">
        <h1 className="leftLogo">Feastify</h1>
        <h1 className="leftTitle">Delete Page</h1>
        <p className="leftText">
          Login to delete your account. We are sad to see you go.
        </p>
      </div>
      <div className="right">
        <h1>Login</h1>
        <input
          className={error ? "input-error" : "input"}
          type="text"
          placeholder="Username"
          value={username}
          onChange={handleUsernameChange}
        />
        <input
          className={error ? "input-error" : "input"}
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        {error && (
          <div style={{ color: "red", marginBottom: 5 }}>
            Invalid username or password. Please try again.
          </div>
        )}

        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default DeletePage;
